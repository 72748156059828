exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-compare-tsx": () => import("./../../../src/pages/compare.tsx" /* webpackChunkName: "component---src-pages-compare-tsx" */),
  "component---src-pages-destination-tsx": () => import("./../../../src/pages/destination.tsx" /* webpackChunkName: "component---src-pages-destination-tsx" */),
  "component---src-pages-diplomatic-passport-tsx": () => import("./../../../src/pages/diplomatic_passport.tsx" /* webpackChunkName: "component---src-pages-diplomatic-passport-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-national-id-tsx": () => import("./../../../src/pages/national_id.tsx" /* webpackChunkName: "component---src-pages-national-id-tsx" */),
  "component---src-pages-official-passport-tsx": () => import("./../../../src/pages/official_passport.tsx" /* webpackChunkName: "component---src-pages-official-passport-tsx" */),
  "component---src-pages-passport-tsx": () => import("./../../../src/pages/passport.tsx" /* webpackChunkName: "component---src-pages-passport-tsx" */),
  "component---src-pages-ranking-tsx": () => import("./../../../src/pages/ranking.tsx" /* webpackChunkName: "component---src-pages-ranking-tsx" */),
  "component---src-templates-destination-tsx": () => import("./../../../src/templates/destination.tsx" /* webpackChunkName: "component---src-templates-destination-tsx" */),
  "component---src-templates-markdown-tsx": () => import("./../../../src/templates/markdown.tsx" /* webpackChunkName: "component---src-templates-markdown-tsx" */),
  "component---src-templates-national-id-tsx": () => import("./../../../src/templates/national_id.tsx" /* webpackChunkName: "component---src-templates-national-id-tsx" */),
  "component---src-templates-passport-tsx": () => import("./../../../src/templates/passport.tsx" /* webpackChunkName: "component---src-templates-passport-tsx" */)
}

